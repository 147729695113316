<template>
  <div class="root">
    <div class="link" @click="toContract">《合作协议与附件一》</div>
    <van-cell-group title="如同意以上协议，请签名！">
      <div class="image" @click="handleShowSignatrue">
        <van-image
          v-if="signatureData"
          width="250px"
          height="100px"
          fit="contain"
          :src="signatureData"
        />
        <div v-else style="height: 100px;line-height: 100px;">
          点击签名</div>
      </div>
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button
        type="primary"
        class="btn"
        @click="next"
        :disabled="!signatureData"
      >
        提交
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
    <div class="signatrueMask" v-show="showSignatrue">
      <div class="signatrue" @touchmove.stop.prevent>
        <canvas
          ref="signatrue"
          class="canvas"
        />
        <div class="btns">
          <van-button
            type="danger"
            class="btn"
            @click="handleClearSignatrue"
          >
            清除
          </van-button>
          <van-button
            type="default"
            class="btn"
            @click="handleCancelSignatrue"
          >
            取消
          </van-button>
          <van-button
            type="primary"
            class="btn"
            @click="handleOkSignatrue"
          >
            确定
          </van-button>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { Button, CellGroup, Image as VanImage } from 'vant';
import SignaturePad from 'signature_pad';
import httpApi from '../utils/httpApi';

export default {
  name: 'Sign',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      showSignatrue: false,
      signaturePad: null,
      signatureData: '',
    };
  },
  async activated() {
    const { params } = this.$route;
    this.$store.commit('setTitleValue', '协议签署');
    this.$store.commit('setStepsShow', false);
    if (params.from === 'agreement') {
      const result = await httpApi.getImage({ type: 'signPic' });
      if (!result) return;
      if (result.code === 200) {
        this.signatureData = result.data.value;
      } else {
        this.$toast(result.msg || '查询失败');
      }
    }
  },
  methods: {
    toContract() {
      this.$router.push('/contract');
    },
    async next() {
      if (!this.signatureData) return;
      const params = { type: 'signPic', photo: this.signatureData };
      const result = await httpApi.updateImage(params);
      if (result) {
        if (result.code === 200) {
          this.$router.push({ path: '/search', query: { type: 'submit' } });
        } else {
          this.$toast(result.msg || '操作失败');
        }
      }
    },
    before() {
      this.$router.back();
    },
    // 显示签名
    handleShowSignatrue() {
      this.showSignatrue = true;
      this.$refs.signatrue.width = window.innerWidth - 44;
      this.$refs.signatrue.height = window.innerHeight - 46;
      // 获取到签名数据之后执行
      this.signaturePad = new SignaturePad(
        this.$refs.signatrue, {
          minWidth: 1,
          maxWidth: 5,
        },
      );
    },

    // 签名确认
    handleOkSignatrue() {
      const data = this.signaturePad && this.signaturePad.toDataURL();
      this.rotateBase64Image(data);
    },

    // 签名清除
    handleClearSignatrue() {
      return this.signaturePad.clear();
    },

    // 签名取消
    handleCancelSignatrue() {
      this.showSignatrue = false;
    },

    // 图片旋转
    rotateBase64Image(base64data) {
      const image = new Image();
      image.src = base64data;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.height = image.width;
        canvas.width = image.height;
        const ctx = canvas.getContext('2d');
        ctx.translate(Math.floor(image.height / 2), Math.floor(image.width / 2));
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        this.showSignatrue = false;
        this.signatureData = canvas.toDataURL();
      };
    },

  },
};
</script>

<style lang="less">
  .root {
    text-align: left;
    padding-top: 20px;
    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }

    .title {
      text-align: center;
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: @space-2 0;
    }

    .signatrueMask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
      display: flex;

      .signatrue {
        position: relative;
        flex: 1;
        background-color: white;

        .canvas {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 44px;
        }

        .btns {
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          display: flex;
          transform:rotate(90deg);
          transform-origin: left bottom;

          .btn {
            width: 50%;
            margin: 0 @space-1;
          }
        }
      }
    }

    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }

</style>
